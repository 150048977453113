<template>
  <div class="container">
    <el-form :model="articleForm" :rules="articleRules" ref="articleForm" label-width="100px">
      <el-form-item label="标题" prop="article_name" style="width: 500px">
        <el-input v-model="articleForm.article_name"></el-input>
      </el-form-item>
      <el-form-item label="文件" prop="outside_url" style="width: 500px">
        
        <el-upload
          class="site-logo"
          :action="MixinUploadApi"
          :show-file-list="true"
          :on-success="(res) => { articleForm.outside_url = res.url }"
          :data="pdfData"
          :multiple="false"
          :file-list="fileList">
          <el-button v-model="articleForm.outside_url">请上传文件</el-button>
          <div class="pre-file" v-if="article_id">
            <img src="../../../assets/pre-file.png" alt="">
            <!-- 文件名称 -->
            {{remark}}
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <UE ref="ue" :defaultMsg="articleForm.content"></UE>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="submitArticleForm" size="small">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import * as API_Article from '@/api/article'
  import { UE } from '@/components'

  export default {
    name: 'addArticle',
    components: { UE },
    data() {
      const { article_id, category } = this.$route.params
      return {
        // 如果是修改，则有值
        article_id,
        // 分类树
        articleCategoryTree: category,
        // 添加、修改文章 表单
        articleForm: {
          article_name: '',
          content: '',
          category_id: '495',
        },
        // 添加、修改文章 表单规则
        articleRules: {
          article_name: [this.MixinRequired('请输入文字名称！')],
          category_id: [this.MixinRequired('请选择文章分类！')],
          content: [this.MixinRequired('请输入文章内容！')]
        },
        // 级联选择器默认值
        defaultCascaderValue: [],
        //上传文件携带的参数
        　pdfData: {
      　　　　scene: 'file'
      　},
        fileList : [],
        //文件名称
        remark : ''
      }
    },
    beforeRouteUpdate(to, from, next) {
      this.article_id = to.params.article_id
      next()
    },
    activated() {
      this.article_id = this.$route.params.article_id
    },
    watch: {
      article_id: 'Get_ArticleData'
    },
    mounted() {
      if (this.article_id) this.Get_ArticleData()
    },
    methods: {
      /** 当分类改变时 */
      handleCascaderChange(data) {
        this.articleForm.category_id = data[data.length - 1]
      },
      /** 添加、编辑文章 表单提交 */
      submitArticleForm() {
        this.articleForm.content = this.$refs['ue'].getUEContent()
        this.$refs['articleForm'].validate((valid) => {
          if (valid) {
            const saveScuess = () => {
              this.$message.success('保存成功！')
              this.$store.dispatch('delCurrentViews', {
                view: this.$route,
                $router: this.$router
              })
            }
            const { article_id } = this
            if (article_id) {
              API_Article.editArticle(article_id, this.articleForm).then(saveScuess)
            } else {
              API_Article.addArticle(this.articleForm).then(saveScuess)
            }
          } else {
            this.$message.error('表单填写有误，请核对！')
            return false
          }
        })
      },
      /** 获取文章详情 */
      Get_ArticleData() {
        API_Article.getArticleDetail(this.article_id).then(res => {
          this.articleForm = res
          this.remark = res.outside_url.split('/')[res.outside_url.split('/').length - 1]
          let d = []
          const { category_id } = res
          this.articleCategoryTree.forEach(item => {
            if (item.id === category_id) {
              d = [item.id]
            } else {
              item.children && item.children.forEach(_item => {
                if (_item.id === category_id) {
                  d = [item.id, _item.id]
                }
              })
            }
          })
          this.defaultCascaderValue = d
        })
      }
    }
  }
</script>

<style scoped>
.pre-file img {
  width: 30px;
  height: 30px;
  margin: 10px
}
</style>


